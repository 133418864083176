.classBlink {
  -webkit-animation: blink 1s linear 6;
  -ms-animation: blink 1s linear 6;
  -moz-animation: blink 1s linear 6;
  animation: blink 1s linear 6;
}
@keyframes blink {
  from {
    box-shadow: 0 0 2rem 0 darkblue;
  }
  50% {
    box-shadow: 0 0 0px 0 darkblue;
  }
  to {
    box-shadow: 0 0 2rem 0 darkblue;
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #c8c5c9;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
