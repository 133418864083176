#sidebar-menu-container {
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
}

a.active .MuiButtonBase-root {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

a:hover .MuiButtonBase-root {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

a .MuiButtonBase-root {
  border-radius: 5px;
  overflow: hidden;
}

.MuiDialogContent-root {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; /* Substitua esses valores pelos desejados */
}
.tableWrap {
  border-radius: 2px;
}
